import { listFields } from "@/components/companies/companiesList/newCompaniesList/data/lists";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allViews: [],
  currentNewView: {
    id: "default",
    title: "Default",
  },
  viewParams: {
    default: {
      columns: listFields?.slice(1)?.map((f) => f?.id),
      columnsWidth: [],
      orderBy: undefined,
      direction: undefined,
      limit: 25,
      text: undefined,
    },
  },
  arrayFilters: { default: undefined },
  savedParams: {
    default: {
      columns: listFields?.slice(1)?.map((f) => f?.id),
      columnsWidth: [],
      orderBy: undefined,
      direction: undefined,
      limit: 25,
      text: undefined,
    },
  },
};

const newViewsSlice = createSlice({
  name: "newViews",
  initialState,
  reducers: {
    setAllViews: (state, { payload }) => {
      state.allViews = payload;
    },
    setCurrentNewView: (state, { payload }) => {
      state.currentNewView = payload;
    },
    setViewParams: (state, { payload }) => {
      state.viewParams = {
        ...state.viewParams,
        [payload?.key]: {
          ...state?.viewParams?.[payload?.key],
          ...payload?.params,
        },
      };
    },
    setSavedViewParams: (state, { payload }) => {
      state.savedParams = {
        ...state.savedParams,
        [payload?.key]: {
          ...state?.savedParams?.[payload?.key],
          ...payload?.params,
          arrayFilters: undefined,
        },
      };
    },
    clearFilterArr: (state, { payload }) => {
      state.arrayFilters = {
        ...state.arrayFilters,
        [payload?.key]: undefined,
      };
    },
    clearViewParams: (state, { payload }) => {
      state.viewParams = {
        ...state.viewParams,
        [payload?.key]: {
          ...state?.viewParams?.[payload?.key],
          ...payload?.params,
        },
      };
    },
    setArrayFilters: (state, { payload }) => {
      state.arrayFilters = {
        ...state.arrayFilters,
        [payload?.key]: payload?.filters,
      };
    },
  },
});

export const newViewActions = newViewsSlice.actions;

export default newViewsSlice;
