import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLists: [],
  currentNewList: { id: "all companies", title: "All Companies" },
  listParams: { limit: 25, page: 1, total: 0 },
};

const newListsSlice = createSlice({
  name: "newLists",
  initialState,
  reducers: {
    setAllLists: (state, { payload }) => {
      state.allLists = payload;
    },
    setCurrentNewList: (state, { payload }) => {
      state.currentNewList = payload;
    },
    setListParams: (state, { payload }) => {
      state.listParams = {
        ...state.listParams,
        ...payload,
      };
    },
  },
});

export const newListActions = newListsSlice.actions;

export default newListsSlice;
