import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  unreadNotifications: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
    setUnreadNotifications: (state, { payload }) => {
      state.unreadNotifications = payload;
    },
  },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;
