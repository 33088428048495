import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  acctFundsList: null,
  currentAcctFund: null,
};

const acctFundsSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    setAcctFundsList: (state, { payload }) => {
      state.acctFundsList = payload;
    },
    setCurrentAcctFund: (state, { payload }) => {
      state.currentAcctFund = payload;
    },
    resetAcctFundsList: (state) => {
      state.acctFundsList = null;
    },
    resetCurrentAcctFund: (state) => {
      state.currentAcctFund = null;
    },
  },
});

export const acctFundsActions = acctFundsSlice.actions;

export default acctFundsSlice;
