import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fundraisingList: null,
  currentPage: null,
  totalPages: null,
  currentFundraising: null,
};

const fundraisingSlice = createSlice({
  name: 'fundraising',
  initialState,
  reducers: {
    setFundraisingList: (state, { payload }) => {
      state.fundraisingList = payload;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setTotalPages: (state, { payload }) => {
      state.totalPages = payload;
    },
    setCurrentFundraising: (state, { payload }) => {
      state.currentFundraising = payload;
    },
  },
});

export const fundraisingActions = fundraisingSlice.actions;

export default fundraisingSlice;
