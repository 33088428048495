import { listFields } from "@/components/companies/companiesList/newCompaniesList/data/lists";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companiesList: [],
  selectedCompanies: [],
  columnsField: listFields?.slice(1)?.map((f) => f?.id),
  isCompaniesLoading: false,
  searchText: undefined,
  isCompaniesCards: false,
};

const noRepeatArrItem = (arr, action) => {
  const itemsToAdd = [];
  action.forEach((itemArr) => {
    const itemArrId = arr?.map((item) => item?.id);
    if (itemArrId?.includes(itemArr?.id)) {
      return;
    }

    itemsToAdd.push(itemArr);
  });

  return itemsToAdd;
};

const newCompaniesListSlice = createSlice({
  name: "newCompaniesList",
  initialState,
  reducers: {
    setNewCompanies: (state, { payload }) => {
      if (!payload?.isCards) {
        state.companiesList = payload?.data;
      } else {
        const uniqItems = noRepeatArrItem(state.companiesList, payload?.data);
        state.companiesList = [...state.companiesList, ...uniqItems];
      }
    },
    setSelectAllCompanies: (state, { payload }) => {
      const uniqItem = noRepeatArrItem(state.selectedCompanies, payload);
      state.selectedCompanies = [...state.selectedCompanies, ...uniqItem];
    },
    clearSelectedCompanies: (state) => {
      state.selectedCompanies = [];
    },
    setSelectedCompanies: (state, { payload }) => {
      state.selectedCompanies?.map((cmp) => cmp?.id)?.includes(payload?.id)
        ? (state.selectedCompanies = state.selectedCompanies.filter(
            (item) => item?.id !== payload?.id
          ))
        : (state.selectedCompanies = [...state.selectedCompanies, payload]);
    },
    setToggleColumnsField: (state, { payload }) => {
      state.columnsField?.includes(payload)
        ? (state.columnsField = state.columnsField.filter(
            (item) => item !== payload
          ))
        : (state.columnsField = [...state.columnsField, payload]);
    },
    setHideAllColumns: (state) => {
      state.columnsField = [];
    },
    setShowAllColumns: (state) => {
      state.columnsField = initialState.columnsField;
    },
    setIsLoadingCompanies: (state, { payload }) => {
      state.isCompaniesLoading = payload;
    },
    removeCompaniesByList: (state, { payload }) => {
      state.companiesList = state.companiesList?.filter(
        (item) => !payload?.includes(item?.id)
      );
    },
    setIsCompaniesCards: (state, { payload }) => {
      state.isCompaniesCards = payload;
    },
    loadMoreForCompaniesCards: (state, { payload }) => {
      const uniqItems = noRepeatArrItem(state.companiesList, payload);
      state.companiesList = [...state.companiesList, ...uniqItems];
    },
  },
});

export const newCompanieListActions = newCompaniesListSlice.actions;

export default newCompaniesListSlice;
