import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: null,
  isConnected: false,
  accreditationQuestions: [],
  notificationsSettings: [],
  userNotificationsSettings: [],
  accreditationForm: null,
  countPage: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, { payload }) => {
      state.profile = payload;
    },
    setCountPage: (state, { payload }) => {
      state.countPage = payload;
    },
    toggleIsConnected: (state, { payload }) => {
      state.isConnected = payload;
    },
    setAccreditattion: (state, { payload }) => {
      state.accreditationQuestions = payload?.sort((a, b) =>
        a?.sequenceNumber > b?.sequenceNumber ? 1 : -1
      );
    },
    setNotificationSettings: (state, { payload }) => {
      const newNotificationsArr = payload?.map((i, index) => {
        return {
          ...i,
          isUser: index === 0 || index === 1 ? true : false,
          option: i?.option?.map((j) => ({
            ...j,
            isUser:
              j?.name === "Every Login" ||
              j?.name === "Login on New Device" ||
              j?.name === "New company research" ||
              j?.name === "New fund research"
                ? true
                : false,
          })),
        };
      });

      state.userNotificationsSettings = newNotificationsArr?.filter(
        (item) => item?.isUser
      );

      state.notificationsSettings = newNotificationsArr;
    },
    setAccreditationForm: (state, { payload }) => {
      state.accreditationForm = {
        ...state.accreditationForm,
        ...payload,
      };
    },
    clearAccreditationForm: (state) => {
      state.accreditationForm = null;
    },
  },
});

export const profileActions = profileSlice.actions;
export default profileSlice;
