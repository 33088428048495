import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  peopleList: null,
  peopleCurrentPage: 1,
  peopleTotalPages: 1,
  currentPerson: null,
};

const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    setPeopleList: (state, { payload }) => {
      if (state.peopleList) {
        state.peopleList = state.peopleList.concat(payload);
      } else {
        state.peopleList = payload;
      }
    },
    setPeopleCurrentPage: (state, { payload }) => {
      state.peopleCurrentPage = payload;
    },
    setPeopleTotalPages: (state, { payload }) => {
      state.peopleTotalPages = payload;
    },
    resetPeopleList: (state) => {
      state.peopleList = null;
      state.peopleCurrentPage = 1;
      state.peopleTotalPages = 1;
    },
    setPersonId: (state, { payload }) => {
      state.currentPerson = payload;
    },
  },
});

export const peopleActions = peopleSlice.actions;

export default peopleSlice;
