import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filtersList: null,
  filteredObjects: null,
  filteredCurrentPage: 1,
  filteredTotalPages: 1,
  paramsForCompaniesCards: {
    direction: undefined,
    orderBy: undefined,
    arrayFilters: {},
    text: undefined,
  },
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFiltersList: (state, { payload }) => {
      state.filtersList = payload;
    },
    setFilteredObjects: (state, { payload }) => {
      state.filteredObjects = !state.filteredObjects
        ? [...payload]
        : [...state.filteredObjects, ...payload];
    },
    setFilteredCurrentPage: (state, { payload }) => {
      state.filteredCurrentPage = payload;
    },
    setFilteredTotalPages: (state, { payload }) => {
      state.filteredTotalPages = payload;
    },
    resetFiltersList: (state) => {
      state.filtersList = null;
    },
    resetFilteredObjects: (state) => {
      state.filteredObjects = null;
      state.filteredCurrentPage = 1;
      state.filteredTotalPages = 1;
    },

    setParamsForCompaniesCard: (state, { payload }) => {
      state.paramsForCompaniesCards = {
        ...state.paramsForCompaniesCards,
        ...payload,
      };
    },
  },
});

export const filtersActions = filtersSlice.actions;
export default filtersSlice;
