import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tagsList: null,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTagsList: (state, { payload }) => {
      state.tagsList = payload;
    },
  },
});

export const tagsActions = tagsSlice.actions;

export default tagsSlice;
