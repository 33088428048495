import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fundsList: null,
  currentFund: null,
  currentPage: null,
  totalPages: null,
  currentPersons: null,
  investmentList: null,
  currentAssetManager: null,
  fundDetails: null,
  currentInvestFund: null,
};

const fundsSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    setFundsList: (state, { payload }) => {
      if (state.fundsList) {
        state.fundsList = state.fundsList.concat(payload);
      } else state.fundsList = payload;
    },
    resetFundsList: (state) => {
      state.fundsList = null;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setTotalPages: (state, { payload }) => {
      state.totalPages = payload;
    },
    setCurrentFund: (state, { payload }) => {
      state.currentFund = payload;
    },
    setCurrentPersons: (state, { payload }) => {
      state.currentPersons = payload;
    },
    setInvestmentList: (state, { payload }) => {
      state.investmentList = payload;
    },
    setCurrentAssetManager: (state, { payload }) => {
      state.currentAssetManager = payload;
    },
    setFundsDetails: (state, { payload }) => {
      state.fundDetails = payload;
    },
    setCurrentInvestFund: (state, { payload }) => {
      state.currentInvestFund = payload;
    },
  },
});

export const fundsActions = fundsSlice.actions;

export default fundsSlice;
