import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resultCompanies: [],
  resultPersons: [],
  resultFunds: [],
  resultTags: [],
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setResultCompanies: (state, { payload }) => {
      state.resultCompanies = payload;
    },
    setResultPersons: (state, { payload }) => {
      state.resultPersons = payload;
    },
    setResultFunds: (state, { payload }) => {
      state.resultFunds = payload;
    },
    setResultTags: (state, { payload }) => {
      state.resultTags = payload;
    },
    resetSearch: (state) => {
      state.resultCompanies = null;
      state.resultPersons = null;
      state.resultFunds = null;
      state.resultTags = null;
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice;
