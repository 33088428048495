import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companiesList: null,
  currentCompany: null,
  currentPage: null,
  totalPages: null,
  companyEmployees: null,
  companyFundraiseHistory: null,
  companyChartData: null,
  investorsListByCompany: null,
  similarCompanies: null,
  researchInsights: null,
  companyDetails: null,
  newsCompany: null,
  // companiesListInComparableSearch: null,
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompaniesList: (state, { payload }) => {
      if (state.companiesList) {
        state.companiesList = state.companiesList.concat(payload);
      } else state.companiesList = payload;
    },
    resetCompaniesList: (state) => {
      state.companiesList = null;
    },
    setCurrentCompany: (state, { payload }) => {
      state.currentCompany = payload;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setTotalPages: (state, { payload }) => {
      state.totalPages = payload;
    },
    setCompanyEmployees: (state, { payload }) => {
      state.companyEmployees = payload;
    },
    setCompanyFundraiseHistory: (state, { payload }) => {
      state.companyFundraiseHistory = payload;
    },
    setCompanyChartData: (state, { payload }) => {
      state.companyChartData = payload;
    },
    setInvestorsListByCompany: (state, { payload }) => {
      state.investorsListByCompany = payload;
    },
    setSimilarCompanies: (state, { payload }) => {
      state.similarCompanies = payload;
    },
    setaResearchInsights: (state, { payload }) => {
      state.researchInsights = payload;
    },
    setCompanyDetails: (state, { payload }) => {
      state.companyDetails = payload;
    },
    removeCompanyFromCompare: (state, { payload }) => {
      state.similarCompanies = [...state.similarCompanies].filter(
        (el) => el?.id !== payload
      );
    },
    setNewsCompany: (state, { payload }) => {
      state.newsCompany = !state.newsCompany
        ? payload
        : [...state.newsCompany, ...payload];
    },

    // setCompaniesListInComparableSearch: (state, { payload }) => {
    //   state.companiesListInComparableSearch = payload;
    // },
    resetNewsCompany: (state) => {
      state.newsCompany = null;
    },
  },
});

export const companiesActions = companiesSlice.actions;

export default companiesSlice;
